<script>
import { required, email } from "vuelidate/lib/validators";
import axios from 'axios';
import { parseErrors } from '../../../helpers';
import httpFxHedge from '@/service-clients/fx-hedge';
import {
  authMethods,
  notificationMethods
} from "@/state/helpers";

export default {
  data() {
    return {
      email: "",
      name: "",
      password: "",
      submitted: false,
      errors:[],
      tryingToRegister: false
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    isFxHedge() {
      return process.env.VUE_APP_HEDGE === 'yes';
    },
    isVH() {
      return process.env.VUE_APP_VH === 'yes';
    },
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  validations: {
    email: { required, email },
    password: { required },
    name: { required }
  },
  methods: {
    ...authMethods,
    ...notificationMethods,
    // Try to log the user in with the username
    // and password they provided.
    async onRegister()  {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
          this.tryingToRegister = true;
        
          this.errors = [];
          const { email, password, name } = this;
          if (email && password && name) {
            const registrationUrl = `${process.env.VUE_APP_PUBLIC_USR_MNGM_URL}users/fx-hedge`;
            try {
              await axios.post(registrationUrl, {
                email, password, name
              });
              this.tryToLogin(email, password);
            } catch(e) {
              console.error(e);
              this.errors= parseErrors(e);
              console.log('errors', this.errors);
            }finally {
              this.tryingToRegister = false;
            }
           
           
          }
      }
    },
    async tryToLogin(userName, password) {
      this.tryingToRegister = true;
      this.logIn({ userName, password })
        .then(async () => {
            this.tryingToRegister = false;
           
            if(this.isFxHedge) {
              // init fx hedge data
              const dto = {
                KeyCcyExposures: ['EUR', 'GBP'],
                BaseCurrency: 'USD',
                CurrencyConfigs: [
                  {Currency: 'EUR', Benchmark: 50, RiskToleranceMin: 0, RiskToleranceMid: 50, RiskToleranceMax: 100, Weight: 50 },
                  {Currency: 'GBP', Benchmark: 50, RiskToleranceMin: 0, RiskToleranceMid: 50, RiskToleranceMax: 100, Weight: 50 }
                ]
                // CurrencyConfigs: this.currencySettings.map(x =>({
                //   Currency: x.currency.Name,
                //   Benchmark: x.hedgeBenchmark,
                //   RiskToleranceMin: x.riskTolerance.min,
                //   RiskToleranceMid: x.riskTolerance.mid,
                //   RiskToleranceMax: x.riskTolerance.max,
                //   Weight: x.weight
                // }))
              };

              await httpFxHedge.post(`user-data`, dto);
            }
          
            
            this.$router.push(
              this.$route.query.redirectFrom || { name: "home" }
            );
          })
          .catch(e => {
              console.error(e);
              this.tryingToRegister = false; 
          });
    }
  }
};
</script>

<template>
  <div>
    
    <div  class="container p-0 ">
      <div>
         <div class="gr-bg text-center p-4 mt-4 mb-4 border-radius" data-aos="fade-right">
            <div class="row">
                <div class="col-xl-4 col-lg-3 col-md-2">

                </div>
                <div class="col-xl-4 col-lg-6  col-md-8" style="position: relative">
                    <img src="@/assets/images/star1.png" class="img-fluid spin-image"/>
                    <span style="position: absolute; left: 0; right: 0; top: 0; bottom: 0;display: flex;justify-content: center;align-items: center;" class="text-center">
                      <img src="@/assets/images/logo_white_hedge.png" alt="LOGO" class="img-fluid " style=" height: 60px;" v-if="isFxHedge"/>
                      <img src="@/assets/images/logo-vh.png" alt="LOGO" class="img-fluid  mt-2 mb-4" style=" height: 60px;" v-else-if="isVH"/>
                      <img src="@/assets/images/c8-logo-w.svg" alt="LOGO" class="img-fluid " style=" height: 50px;" v-else/>
                    </span>
                </div>
                <div class="col-xl-4  col-lg-3  col-md-2">

                </div>
            </div>     
          </div>
          <b-alert
              variant="danger"
              class="mt-3 mb-3"
              v-if="errors.length > 0"
              show
              dismissible
            >
            <ul>
              <li v-for="(e, ei) in errors" :key="ei">{{ e }}</li>
            </ul>
          </b-alert>
        <form class="form-horizontal mt-4" @submit.prevent="onRegister">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group auth-form-group-custom mb-4">
                <i class="ri-user-3-line auti-custom-input-icon"></i>
                <label for="name">Name</label>
                <input
                  
                  v-model="name"
                  class="form-control"
                  id="name"
                  placeholder=""
                  :class="{ 'is-invalid': submitted && $v.email.$error }"
                />
                <div v-if="submitted && $v.name.$error" class="invalid-feedback">
                  <span v-if="!$v.name.required">Name is required field</span>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group auth-form-group-custom mb-4">
                <i class="ri-mail-line auti-custom-input-icon"></i>
                <label for="email">Email</label>
                <input
                  type="text"
                  v-model="email"
                  class="form-control"
                  id="email"
                  placeholder=""
                  :class="{ 'is-invalid': submitted && $v.email.$error }"
                  autocomplete="username"
                />
                <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                  <span v-if="!$v.email.required">Email is required field.</span>
                  <span v-if="!$v.email.email">Email is not valid.</span>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group auth-form-group-custom mb-4">
                <i class="ri-lock-2-line auti-custom-input-icon"></i>
                <label for="userpassword">Password</label>
                <input
                  v-model="password"
                  type="password"
                  class="form-control"
                  id="userpassword"
                  placeholder=""
                  :class="{ 'is-invalid': submitted && $v.password.$error }"
                  autocomplete="new-password"
                />
                <div
                  v-if="submitted && !$v.password.required"
                  class="invalid-feedback"
                >Password is required field.</div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="d-flex align-items-middle mt-4 justify-content-center">
                <button
                  class="btn btn-primary w-md waves-effect waves-light "
                  type="submit"
                  :disabled="tryingToRegister"
                >
                  <i class="fa fa-spin fa-spinner" v-if="tryingToRegister"></i>
                  Register account
                </button>
               
              
              </div>
              <div class="d-flex mt-4 justify-content-center">
                <span>
                  If you already have an account, just <a href="/login">login</a>.
                </span>
              
              </div>
            </div>
          </div>
        </form>
        
      </div>
    </div>
  </div>
</template>